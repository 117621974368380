<script>
//import RoundLauncher from './components/Launchers/RoundLauncher.vue';

export default {
    props: {
        waitMode: { type: Boolean, Default: false },
    },
    data(){
        return { d_waitMode: this.waitMode, };
    },
    //components: { RoundLauncher, },
    watch:{
        waitMode: function(newValue){
            if(newValue != this.d_waitMode)
                this.d_waitMode = newValue;
        }
    }
}
</script>

<template>
    <div>
        <!--
        <slot name="prepend"></slot>

        <slot waitMode="d_waitMode">
          <collapse-panel v-for="round in currentCompetition.ROUNDS" :variant="round.isCompleted && round.isDebriefingCompletedOrSkipped ? 'success' : ( round.isStarted ? 'warning' : (!round.isReadyToBriefing ? 'danger' : ''))/*{'success': round.isCompleted, 'warning': round.isStarted && !round.isCompleted}*/" :visible="!round.isCompleted && round.isReadyToProcess" :key="round.round_number" :title="'Tour/Manche' + round.round_number" lazy>
            <round-launcher  
                :round="round"
                :waitMode="d_waitMode"
                @startWaitingForEvent="d_waitMode=true"
                @stopWaitingForEvent="d_waitMode=false"
            >
              <template #briefing></template>
            </round-launcher>
          </collapse-panel>

          <b-button :to="{name: 'CompetitionResults'}" style="width:100%; height:3rem;" ><b-icon icon="trophee"/> Résultats de la compétition & classement général</b-button>
        </slot>

        <slot name="postpend"></slot>
        -->
    </div>

</template>
